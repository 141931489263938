/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "@ionic/storage";


@Injectable({providedIn:"root"})
export class TranslateConfiguration {
	
	constructor(
		public translateService: TranslateService,
		public storage: Storage
	){

		this.setDefaultLanguage();


	}



	
	current_language:string = "es";
	
	
	/**
	* Set Locale
	**/
	set locale(value: string) {
		this.current_language = value;
	}
	
	
	/**
	* Get Locale
	**/
	get locale(): string {
		return this.current_language || "es";
	}
	
	
	/**
	* TranslateService:setDefaultLanguage()
	**/
	setDefaultLanguage(){
		let language = this.current_language;
		this.storage.get(`locale`).then(new_language=>{
			if(new_language != null){
				language = new_language;
				this.current_language = new_language;
			}
			console.log(`TranslateConfiguration`,`setDefaultLanguage`,language);
			this.translateService.setDefaultLang(language);
		});
	}
	
	
	/**
	* TranslateService:setLanguage(setLang:string)
	**/
	setLanguage(setLang:string){
		console.log(`TranslateConfiguration`,`setLanguage`,setLang);
		this.translateService.use(setLang);
	}
	



}
