/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 * ReadMore pipe
 * Split text and give a trail

 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */


@Pipe({
	name: "readMore",
})

export class ReadMorePipe implements PipeTransform {

	constructor(
		public translateService: TranslateService
	){



	}

	transform(text:string,args:number): string{

			let trail = '...';
			let limit = args > 0 ? args : 100;
			return text.length > limit ? text.substring(0, limit) + trail : text;


	}
}
