/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "@ionic/storage";
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { retry } from "rxjs/operators";
import { LoadingController } from "@ionic/angular";
import { ToastController } from "@ionic/angular";
import { AlertController } from "@ionic/angular";



@Injectable({
	providedIn: "root"
})

export class JwtAuthService {
	
	constructor(
		public httpClient: HttpClient,
		public loadingController: LoadingController,
		public toastController: ToastController,
		public alertController: AlertController,
		private storage: Storage,
		public translateService: TranslateService
	){



	}


	
	urlToken: string = "https://www.boqueixon.gal/wp-json/jwt-auth/v1/token"; // This is the entry point for the JWT Authentication
	urlValidate: string = "https://www.boqueixon.gal/wp-json/jwt-auth/v1/token/validate"; // This is a simple helper endpoint to validate a token
	urlRegister: string = "https://www.boqueixon.gal/wp-json/wp/v2/users/register"; // This is the entry point for register new user
	
	
	loading: any ;
	
	
	/**
	/ JwtAuthService.inputFields($obj)
	* @param any $obj
	**/
	private inputFields(field:any){
		let inputs = {
			"username": field.username,
			"password": field.password
		}
		return this.httpBuildQuery(inputs);
	}
	
	
	/**
	* JwtAuthService.httpBuildQuery(obj)
	* @param object $obj
	**/
	private httpBuildQuery(obj) {
		let k:any;
		let str:any = [];
		for (k in obj) {
			str.push(encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]));
		}
		return str.join("&");
	}
	
	
	/**
	* JwtAuthService.register(any)
	* @param any $any
	**/
	register(fields:any): Observable<any>{
		//console.log(fields);
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json"
			})
		}
		this.presentLoading();
		return this.httpClient.post(this.urlRegister,fields, httpOptions)
			.pipe(
				map(results => {
					console.log(`services`,`register`,results);
					this.dismissLoading();
					this.showToast(`Se ha registrado el usuario!`);
					return results;
				}),
				catchError(err => {
					if(err && err.error && err.error.message){
						this.showAlert(err.statusText,null,err.error.message);
					}else{
						this.showToast(err.statusText);
					}
					return throwError(err);
				}),
				catchError(err => {
					console.log("caught rethrown:", err);
					return of([]);
				})
			);
	}
	
	
	/**
	* JwtAuthService.checkToken(string)
	* @param any $string
	**/
	checkToken(token:string): Observable<any>{
		//console.log(fields);
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": "Bearer " + token
			})
		}
		this.presentLoading();
		return this.httpClient.post(this.urlValidate,null,httpOptions)
			.pipe(
				map(results => {
					console.log(`services`,`checkToken`,results);
					this.dismissLoading();
					this.showToast(`Se ha validado el acceso`);
					return results;
				}),
				catchError(err => {
					console.log("Handling error:", err);
					this.showToast(err.statusText);
					return throwError(err);
				}),
				catchError(err => {
					console.log("caught rethrown:", err);
					return of([]);
				})
			);
		
	}
	
	
	/**
	* JwtAuthService.getToken(any)
	* @param any $any
	**/
	getToken(fields:any): Observable<any>{
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/x-www-form-urlencoded"
			})
		}
		this.presentLoading();
		return this.httpClient.post(this.urlToken,this.inputFields(fields), httpOptions)
			.pipe(
				map(results => {
					console.log(`services`,`getToken`,results);
					this.dismissLoading();
					this.showToast(`Se ha validado el usuario`);
					return results;
				}),
				catchError(err => {
					console.log("Handling error:", err);
					if(err && err.error && err.error.code){
						this.showAlert(err.statusText,null,err.error.message);
					}else{
						this.showToast(err.statusText);
					}
					return throwError(err);
				}),
				catchError(err => {
					console.log("caught rethrown:", err);
					return of([]);
				})
			);
	}
	
	
	/**
	* JwtAuthService.presentLoading()
	**/
	async presentLoading() {
		this.loading = await this.loadingController.create({
			message: `Por favor, espere...`,
			spinner: "crescent",
			duration: 500
		});
		await this.loading.present();
	}
	
	
	/**
	* JwtAuthService.dismissLoading()
	**/
	async dismissLoading() {
		if(this.loading){
			await this.loading.dismiss();
		}
	}
	
	
	/**
	* JwtAuthService.showToast()
	**/
	async showToast(message: string){
		const toast = await this.toastController.create({
			message: message,
			position: "bottom",
			color: "dark",
			duration: 500
		});
		await toast.present();
	}
	
	
	/**
	* JwtAuthService.showAlert()
	**/
	async showAlert(header:string, subheader: string, message: string){
		const alert = await this.alertController.create({
			header: header,
			subHeader: subheader,
			message: this.stripTags(message),
			buttons: [`Ok!`]
		});
		await alert.present();
	}
	
	
	/**
	* JwtAuthService.stripTags(text,any[])
	**/
	private stripTags(text:string, ...allowedTags:any[]): string{
		return allowedTags.length > 0
		? text.replace(new RegExp(`<(?!\/?(${allowedTags.join('|')})\s*\/?)[^>]+>`, 'g'), '')
		: text.replace(/<(?:.|\s)*?>/g, '');
	}
	
	



}

