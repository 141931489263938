/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Directive, HostListener, Input, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";

@Directive({
	selector: "[callApp]"
})

export class CallAppDirective {

	@Input() phoneNumber: string;

	constructor( 
		private elementRef: ElementRef,
		public translateService: TranslateService,
		private inAppBrowser: InAppBrowser
	 ){


	}






	@HostListener("click", ["$event"]) onClick(e){
		this.runDialApp(this.phoneNumber);
	}


	
	/**
	* runDialApp($phone_number)
	* @param string $phone_number = "082233333734"
	**/
	
	public runDialApp(phone_number: string){
		let phoneNumber = phone_number || "08123456789";
		let urlSchema = "tel:" + phoneNumber ;
		this.inAppBrowser.create(urlSchema,"_system");
	}
	


}
