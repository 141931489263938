/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 * ObjectLength pipe
 * Get the object length

 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */


@Pipe({
	name: "objectLength",
})

export class ObjectLengthPipe implements PipeTransform {

	constructor(
		public translateService: TranslateService
	){



	}

	transform(obj:any): number{

			let keys = Object.keys(obj);
			return keys.length;


	}
}
