/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

const routes: Routes = [
	{
		path: "",
		redirectTo: "home",
		pathMatch: "full"
	},
	{
		path: "alojamientos-y-hosteleria",
		loadChildren: () => import("./pages/alojamientos-y-hosteleria/alojamientos-y-hosteleria.module").then(m => m.AlojamientosYHosteleriaPageModule)
	},
	{
		path: "bibliografa--translate--",
		loadChildren: () => import("./pages/bibliografa--translate--/bibliografa--translate--.module").then(m => m.BibliografaTranslatePageModule)
	},
	{
		path: "bookmarks",
		loadChildren: () => import("./pages/bookmarks/bookmarks.module").then(m => m.BookmarksPageModule)
	},
	{
		path: "calle-de-la-reina-lupa-en",
		loadChildren: () => import("./pages/calle-de-la-reina-lupa-en/calle-de-la-reina-lupa-en.module").then(m => m.CalleDeLaReinaLupaEnPageModule)
	},
	{
		path: "calle-de-la-reina-lupa-es",
		loadChildren: () => import("./pages/calle-de-la-reina-lupa-es/calle-de-la-reina-lupa-es.module").then(m => m.CalleDeLaReinaLupaEsPageModule)
	},
	{
		path: "calle-de-la-reina-lupa-gl",
		loadChildren: () => import("./pages/calle-de-la-reina-lupa-gl/calle-de-la-reina-lupa-gl.module").then(m => m.CalleDeLaReinaLupaGlPageModule)
	},
	{
		path: "camara",
		loadChildren: () => import("./pages/camara/camara.module").then(m => m.CamaraPageModule)
	},
	{
		path: "camino-en",
		loadChildren: () => import("./pages/camino-en/camino-en.module").then(m => m.CaminoEnPageModule)
	},
	{
		path: "camino-es",
		loadChildren: () => import("./pages/camino-es/camino-es.module").then(m => m.CaminoEsPageModule)
	},
	{
		path: "camino-gl",
		loadChildren: () => import("./pages/camino-gl/camino-gl.module").then(m => m.CaminoGlPageModule)
	},
	{
		path: "categories",
		loadChildren: () => import("./pages/categories/categories.module").then(m => m.CategoriesPageModule)
	},
	{
		path: "centro-de-interpretacin-en",
		loadChildren: () => import("./pages/centro-de-interpretacin-en/centro-de-interpretacin-en.module").then(m => m.CentroDeInterpretacinEnPageModule)
	},
	{
		path: "centro-de-interpretacin-gl",
		loadChildren: () => import("./pages/centro-de-interpretacin-gl/centro-de-interpretacin-gl.module").then(m => m.CentroDeInterpretacinGlPageModule)
	},
	{
		path: "centro-de-interpretacin",
		loadChildren: () => import("./pages/centro-de-interpretacin/centro-de-interpretacin.module").then(m => m.CentroDeInterpretacinPageModule)
	},
	{
		path: "codigos-qr",
		loadChildren: () => import("./pages/codigos-qr/codigos-qr.module").then(m => m.CodigosQrPageModule)
	},
	{
		path: "contacta",
		loadChildren: () => import("./pages/contacta/contacta.module").then(m => m.ContactaPageModule)
	},
	{
		path: "cueva-este-en",
		loadChildren: () => import("./pages/cueva-este-en/cueva-este-en.module").then(m => m.CuevaEsteEnPageModule)
	},
	{
		path: "cueva-este-es",
		loadChildren: () => import("./pages/cueva-este-es/cueva-este-es.module").then(m => m.CuevaEsteEsPageModule)
	},
	{
		path: "cueva-este-gl",
		loadChildren: () => import("./pages/cueva-este-gl/cueva-este-gl.module").then(m => m.CuevaEsteGlPageModule)
	},
	{
		path: "cueva-norte-en",
		loadChildren: () => import("./pages/cueva-norte-en/cueva-norte-en.module").then(m => m.CuevaNorteEnPageModule)
	},
	{
		path: "cueva-norte-es",
		loadChildren: () => import("./pages/cueva-norte-es/cueva-norte-es.module").then(m => m.CuevaNorteEsPageModule)
	},
	{
		path: "cueva-norte-gl",
		loadChildren: () => import("./pages/cueva-norte-gl/cueva-norte-gl.module").then(m => m.CuevaNorteGlPageModule)
	},
	{
		path: "denied",
		loadChildren: () => import("./pages/denied/denied.module").then(m => m.DeniedPageModule)
	},
	{
		path: "el-monte-sagrado-en",
		loadChildren: () => import("./pages/el-monte-sagrado-en/el-monte-sagrado-en.module").then(m => m.ElMonteSagradoEnPageModule)
	},
	{
		path: "el-monte-sagrado-es",
		loadChildren: () => import("./pages/el-monte-sagrado-es/el-monte-sagrado-es.module").then(m => m.ElMonteSagradoEsPageModule)
	},
	{
		path: "el-monte-sagrado-gl",
		loadChildren: () => import("./pages/el-monte-sagrado-gl/el-monte-sagrado-gl.module").then(m => m.ElMonteSagradoGlPageModule)
	},
	{
		path: "el-regulo-en",
		loadChildren: () => import("./pages/el-regulo-en/el-regulo-en.module").then(m => m.ElReguloEnPageModule)
	},
	{
		path: "el-regulo-es",
		loadChildren: () => import("./pages/el-regulo-es/el-regulo-es.module").then(m => m.ElReguloEsPageModule)
	},
	{
		path: "el-regulo-gl",
		loadChildren: () => import("./pages/el-regulo-gl/el-regulo-gl.module").then(m => m.ElReguloGlPageModule)
	},
	{
		path: "en-preparacion",
		loadChildren: () => import("./pages/en-preparacion/en-preparacion.module").then(m => m.EnPreparacionPageModule)
	},
	{
		path: "enviar-aviso",
		loadChildren: () => import("./pages/enviar-aviso/enviar-aviso.module").then(m => m.EnviarAvisoPageModule)
	},
	{
		path: "eventos",
		loadChildren: () => import("./pages/eventos/eventos.module").then(m => m.EventosPageModule)
	},
	{
		path: "fiestas-de-inters-turstico-en",
		loadChildren: () => import("./pages/fiestas-de-inters-turstico-en/fiestas-de-inters-turstico-en.module").then(m => m.FiestasDeIntersTursticoEnPageModule)
	},
	{
		path: "fiestas-de-inters-turstico-gl",
		loadChildren: () => import("./pages/fiestas-de-inters-turstico-gl/fiestas-de-inters-turstico-gl.module").then(m => m.FiestasDeIntersTursticoGlPageModule)
	},
	{
		path: "fiestas-de-inters-turstico",
		loadChildren: () => import("./pages/fiestas-de-inters-turstico/fiestas-de-inters-turstico.module").then(m => m.FiestasDeIntersTursticoPageModule)
	},
	{
		path: "fortaleza-en",
		loadChildren: () => import("./pages/fortaleza-en/fortaleza-en.module").then(m => m.FortalezaEnPageModule)
	},
	{
		path: "fortaleza-es",
		loadChildren: () => import("./pages/fortaleza-es/fortaleza-es.module").then(m => m.FortalezaEsPageModule)
	},
	{
		path: "fortaleza-gl",
		loadChildren: () => import("./pages/fortaleza-gl/fortaleza-gl.module").then(m => m.FortalezaGlPageModule)
	},
	{
		path: "geologia-en",
		loadChildren: () => import("./pages/geologia-en/geologia-en.module").then(m => m.GeologiaEnPageModule)
	},
	{
		path: "geologia-es",
		loadChildren: () => import("./pages/geologia-es/geologia-es.module").then(m => m.GeologiaEsPageModule)
	},
	{
		path: "geologia-gl",
		loadChildren: () => import("./pages/geologia-gl/geologia-gl.module").then(m => m.GeologiaGlPageModule)
	},
	{
		path: "granxa-en",
		loadChildren: () => import("./pages/granxa-en/granxa-en.module").then(m => m.GranxaEnPageModule)
	},
	{
		path: "granxa-es",
		loadChildren: () => import("./pages/granxa-es/granxa-es.module").then(m => m.GranxaEsPageModule)
	},
	{
		path: "granxa-gl",
		loadChildren: () => import("./pages/granxa-gl/granxa-gl.module").then(m => m.GranxaGlPageModule)
	},
	{
		path: "home",
		loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule)
	},
	{
		path: "iglesia-lestedo-en",
		loadChildren: () => import("./pages/iglesia-lestedo-en/iglesia-lestedo-en.module").then(m => m.IglesiaLestedoEnPageModule)
	},
	{
		path: "iglesia-lestedo-es",
		loadChildren: () => import("./pages/iglesia-lestedo-es/iglesia-lestedo-es.module").then(m => m.IglesiaLestedoEsPageModule)
	},
	{
		path: "iglesia-lestedo-gl",
		loadChildren: () => import("./pages/iglesia-lestedo-gl/iglesia-lestedo-gl.module").then(m => m.IglesiaLestedoGlPageModule)
	},
	{
		path: "la-capilla-del-pico-en",
		loadChildren: () => import("./pages/la-capilla-del-pico-en/la-capilla-del-pico-en.module").then(m => m.LaCapillaDelPicoEnPageModule)
	},
	{
		path: "la-capilla-del-pico-es",
		loadChildren: () => import("./pages/la-capilla-del-pico-es/la-capilla-del-pico-es.module").then(m => m.LaCapillaDelPicoEsPageModule)
	},
	{
		path: "la-capilla-del-pico-gl",
		loadChildren: () => import("./pages/la-capilla-del-pico-gl/la-capilla-del-pico-gl.module").then(m => m.LaCapillaDelPicoGlPageModule)
	},
	{
		path: "localizacin",
		loadChildren: () => import("./pages/localizacin/localizacin.module").then(m => m.LocalizacinPageModule)
	},
	{
		path: "localizacion-en",
		loadChildren: () => import("./pages/localizacion-en/localizacion-en.module").then(m => m.LocalizacionEnPageModule)
	},
	{
		path: "localizacion-gl",
		loadChildren: () => import("./pages/localizacion-gl/localizacion-gl.module").then(m => m.LocalizacionGlPageModule)
	},
	{
		path: "login",
		loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule)
	},
	{
		path: "lourdes-en",
		loadChildren: () => import("./pages/lourdes-en/lourdes-en.module").then(m => m.LourdesEnPageModule)
	},
	{
		path: "lourdes-es",
		loadChildren: () => import("./pages/lourdes-es/lourdes-es.module").then(m => m.LourdesEsPageModule)
	},
	{
		path: "lourdes-gl",
		loadChildren: () => import("./pages/lourdes-gl/lourdes-gl.module").then(m => m.LourdesGlPageModule)
	},
	{
		path: "mirador-en",
		loadChildren: () => import("./pages/mirador-en/mirador-en.module").then(m => m.MiradorEnPageModule)
	},
	{
		path: "mirador-es",
		loadChildren: () => import("./pages/mirador-es/mirador-es.module").then(m => m.MiradorEsPageModule)
	},
	{
		path: "mirador-gl",
		loadChildren: () => import("./pages/mirador-gl/mirador-gl.module").then(m => m.MiradorGlPageModule)
	},
	{
		path: "post-detail",
		loadChildren: () => import("./pages/post-detail/post-detail.module").then(m => m.PostDetailPageModule)
	},
	{
		path: "post-detail/:post_id",
		loadChildren: () => import("./pages/post-detail/post-detail.module").then(m => m.PostDetailPageModule)
	},
	{
		path: "posts-by-author",
		loadChildren: () => import("./pages/posts-by-author/posts-by-author.module").then(m => m.PostsByAuthorPageModule)
	},
	{
		path: "posts-by-author/:data_id",
		loadChildren: () => import("./pages/posts-by-author/posts-by-author.module").then(m => m.PostsByAuthorPageModule)
	},
	{
		path: "posts-by-categories-detail",
		loadChildren: () => import("./pages/posts-by-categories-detail/posts-by-categories-detail.module").then(m => m.PostsByCategoriesDetailPageModule)
	},
	{
		path: "posts-by-categories-detail/:post_id",
		loadChildren: () => import("./pages/posts-by-categories-detail/posts-by-categories-detail.module").then(m => m.PostsByCategoriesDetailPageModule)
	},
	{
		path: "posts-by-categories",
		loadChildren: () => import("./pages/posts-by-categories/posts-by-categories.module").then(m => m.PostsByCategoriesPageModule)
	},
	{
		path: "posts-by-tags",
		loadChildren: () => import("./pages/posts-by-tags/posts-by-tags.module").then(m => m.PostsByTagsPageModule)
	},
	{
		path: "posts-by-tags/:data_id",
		loadChildren: () => import("./pages/posts-by-tags/posts-by-tags.module").then(m => m.PostsByTagsPageModule)
	},
	{
		path: "presentacion-gl",
		loadChildren: () => import("./pages/presentacion-gl/presentacion-gl.module").then(m => m.PresentacionGlPageModule)
	},
	{
		path: "presentacion",
		loadChildren: () => import("./pages/presentacion/presentacion.module").then(m => m.PresentacionPageModule)
	},
	{
		path: "presentation",
		loadChildren: () => import("./pages/presentation/presentation.module").then(m => m.PresentationPageModule)
	},
	{
		path: "puntos-de-inters",
		loadChildren: () => import("./pages/puntos-de-inters/puntos-de-inters.module").then(m => m.PuntosDeIntersPageModule)
	},
	{
		path: "radio",
		loadChildren: () => import("./pages/radio/radio.module").then(m => m.RadioPageModule)
	},
	{
		path: "register",
		loadChildren: () => import("./pages/register/register.module").then(m => m.RegisterPageModule)
	},
	{
		path: "tabs",
		loadChildren: () => import("./pages/tabs/tabs.module").then(m => m.TabsPageModule)
	},
	{
		path: "visita-fsica--translate--",
		loadChildren: () => import("./pages/visita-fsica--translate--/visita-fsica--translate--.module").then(m => m.VisitaFsicaTranslatePageModule)
	},
	{
		path: "visita-virtual",
		loadChildren: () => import("./pages/visita-virtual/visita-virtual.module").then(m => m.VisitaVirtualPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
