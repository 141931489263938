/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Component } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { AppSideMenus } from "./app.side-menus";

import { Platform, NavController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { InAppBrowser,InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from "@angular/router";
import { Device } from "@ionic-native/device/ngx";
import { Globals } from "./class/globals/globals";
import { TranslateConfiguration } from "./class/translate-configuration/translate-configuration";
import { environment } from "./../../src/environments/environment";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { Observable } from "rxjs";
import { JwtAuthService } from "./services/jwt-auth/jwt-auth.service";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { Shake } from "@ionic-native/shake/ngx";


/**
* OneSignal Setting
**/
const oneSignalAppId = "1c0eb303-75bc-48fb-b706-771b6eeb7903"; 
const firebaseSenderID = "329373765217";


@Component({
	selector: "app-root",
	templateUrl: "app.component.html"
})
export class AppComponent {

	appTitle:string = "<img src='./assets/images/background/boqini.png'>";
	appSubTitle:string = "";
	appMenus:any = [] ;



	/**
	* Boqueixon:constructor()
	**/

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private menuController: MenuController,
		private platform: Platform,
		private storage: Storage,
		private statusBar: StatusBar,
		private navController: NavController,
		private splashScreen: SplashScreen,
		private device: Device,
		private appSideMenus: AppSideMenus,
		private globals: Globals,
		private translateConfiguration: TranslateConfiguration,
		private deeplinks: Deeplinks,
		private jwtAuthService: JwtAuthService,
		private oneSignal: OneSignal,
		private shake: Shake){
			//initialization items for static menu
			this.appMenus = this.appSideMenus.items;
			//initialization app
			this.initializeApp();
	}
	

	/**
	* Boqueixon:initializeApp()
	**/

	initializeApp() {
		this.platform.ready().then(() => {

			// set status bar
			this.statusBar.backgroundColorByHexString("#0955b3");

			// hide splashscreen
			this.splashScreen.hide();

			// translate
			this.translateConfiguration.setDefaultLanguage();
			this.router.events.subscribe((event: Event) =>{
				if(event instanceof NavigationStart){
					this.translateConfiguration.setDefaultLanguage();
				};
			});


		});


		// This code is used for Deeplinks
		this.handlerDeeplinks();

		// Authentication
this.handlerJWT();

		// This code is used for One Signal
this.handlerNotifications();

		// This code is used for Shake Detection
		this.handlerShake();

		this.handlerBackButton();
		
	}


	/**
	* Boqueixon:handlerBackButton();
	**/
	private handlerBackButton(){
		let pageName = `home`;
		this.router.events.subscribe((event: Event) =>{
			if(event instanceof NavigationStart){
				let getPage = event.url.toString().split("/");
				pageName = getPage[1];
				console.log(`pageName`,pageName);
			}
		});
		this.platform.backButton.subscribeWithPriority(666666,()=>{
			if(( pageName == "" ) || ( pageName == `home` )){
				console.log(`Hardware Exit`);
				if(window.confirm("Do you want to exit App?")){
					navigator["app"].exitApp(); 
				}
			}else{
				console.log(`Hardware Back`);
				this.navController.back();
			}
		})
	}


	/**
	* Boqueixon:handlerDeeplinks()
	**/
	public handlerDeeplinks(){
		this.platform.ready().then(() =>{
			if(this.platform.is("cordova")){
				this.deeplinks.route({
					"/" : {},
					"/about-us" : {AboutUsPage:true},
					"/alojamiento" : {AlojamientoPage:true},
					"/bookmarks" : {BookmarksPage:true},
					"/categories" : {CategoriesPage:true},
					"/centro-de-interpretacin" : {CentroDeInterpretacinPage:true},
					"/contacta" : {ContactaPage:true},
					"/en-preparacion" : {EnPreparacionPage:true},
					"/eventos" : {EventosPage:true},
					"/faqs" : {FaqsPage:true},
					"/home" : {HomePage:true},
					"/localizacin" : {LocalizacinPage:true},
					"/post-detail" : {PostDetailPage:true},
					"/post-detail/:post_id" : {PostDetailPage:true},
					"/posts-by-author" : {PostsByAuthorPage:true},
					"/posts-by-author/:data_id" : {PostsByAuthorPage:true},
					"/posts-by-categories-detail" : {PostsByCategoriesDetailPage:true},
					"/posts-by-categories-detail/:post_id" : {PostsByCategoriesDetailPage:true},
					"/posts-by-categories" : {PostsByCategoriesPage:true},
					"/posts-by-tags" : {PostsByTagsPage:true},
					"/posts-by-tags/:data_id" : {PostsByTagsPage:true},
					"/presentacion" : {PresentacionPage:true},
					"/privacy-policy" : {PrivacyPolicyPage:true},
					"/puntos-de-inters" : {PuntosDeIntersPage:true},
					"/users" : {UsersPage:true},
					"/visita-virtual" : {VisitaVirtualPage:true}		
				}).subscribe(match =>  {
		
					console.log("matched route", match);
					console.log("goto", match.$link.path);
					this.router.navigate([match.$link.path]);
					console.log("Successfully matched route", match);
		
				}, nomatch => {
					console.error("Got a deeplink that didn't match", nomatch);
				});
				
			}
		});
	}

validToken: Observable<any>;
	validDataToken: any = [];
			
			
	/**
	* Boqueixon:handlerJWT()
	**/
	private handlerJWT(){
		this.platform.ready().then(() =>{
			
			this.router.events.subscribe((event: Event) =>{
				if(event instanceof NavigationStart){
					let getPage = event.url.toString().split("/");
					let pageName = getPage[1];
					if(pageName ==""){
						pageName = `home`;
					}
					console.log("pageName",pageName);
					
					// required auth
					if((pageName == "enviar-aviso") || 
						(pageName == "en-preparacion")){
			
			
						this.storage.get("current_user").then((current_user) => {
							console.log(`storage`,`current_user`, current_user);
							if(current_user && current_user.token){
								this.validToken = this.jwtAuthService.checkToken(current_user.token);
								this.validToken.subscribe(dataToken =>{
									this.validDataToken = dataToken ;
									console.log("online","validate", dataToken.data);
									if(typeof(dataToken.data) == "undefined"){
										
										// goto login page
										if((pageName == "enviar-aviso") || 
											(pageName == "en-preparacion")){
											this.router.navigate(["/login"]);
										}
										return false;
									}
									if(dataToken.data.status == 200){
										// current user info
										this.appSubTitle = current_user.user_email;
									}else{
										
										// goto login page
										if((pageName == "enviar-aviso") || 
											(pageName == "en-preparacion")){
											this.router.navigate(["/login"]);
										}
										return false;
									}
								});
							}else{
								
								// goto login page
								if((pageName == "enviar-aviso") || 
								(pageName == "en-preparacion")){
									this.router.navigate(["/login"]);
								}
							}
						});
						
						
					}
				}
			});
		});
	}
/**
	* Ategal:handlerNotifications()
	**/
	public handlerNotifications(){
		this.platform.ready().then(() =>{
			if (this.platform.is("cordova")){
				if (this.platform.is("android")){
					this.oneSignal.startInit(oneSignalAppId,firebaseSenderID);
				}else{
					this.oneSignal.startInit(oneSignalAppId);
				}
				this.oneSignal.endInit();
			}
		});
	}
	
	
	
	/**
	* Boqueixon:handlerShake()
	**/
	public handlerShake(){
		this.platform.ready().then(() =>{
			if (this.platform.is("cordova")){
				const watch = this.shake.startWatch(60).subscribe(() => {
					this.router.navigate(["/radio"]);
				});
			}
		});
	}
	
	


}
