/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Injectable } from "@angular/core";

@Injectable()

export class AppSideMenus{
	items:any = [
    {
        "item_type": "title",
        "item_label": "<strong>O CONCELLO<\/strong>",
        "item_var": "strongo_concellostrong",
        "item_link": "\/",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "primary",
        "item_icon_left": "information-circle-sharp",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Inicio",
        "item_var": "home",
        "item_link": "\/home",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Actualidad Concello",
        "item_var": "posts_by_categories",
        "item_link": "\/posts-by-categories",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Enviar aviso",
        "item_var": "enviar_aviso",
        "item_link": "\/enviar-aviso",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Alojamientos y hosteler\u00eda",
        "item_var": "alojamientos_y_hosteleria",
        "item_link": "\/alojamientos-y-hosteleria",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Fiestas de inter\u00e9s tur\u00edstico",
        "item_var": "fiestas_de_inters_turstico",
        "item_link": "\/fiestas-de-inters-turstico",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Contacta",
        "item_var": "contacta",
        "item_link": "\/contacta",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "title",
        "item_label": "<strong>O PICO SACRO<\/strong>",
        "item_var": "pico_sacro",
        "item_link": "\/",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "primary",
        "item_icon_left": "information-circle-sharp",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Presentaci\u00f3n",
        "item_var": "presentacion",
        "item_link": "\/presentacion",
        "item_value": "https:\/\/formacion.Boqueixon.com",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "tablet-landscape",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Mirador",
        "item_var": "mirador_es",
        "item_link": "\/mirador-es",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "aperture-sharp",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Localizaci\u00f3n",
        "item_var": "localizacin",
        "item_link": "\/localizacin",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "map-sharp",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Puntos de inter\u00e9s",
        "item_var": "puntos_de_inters",
        "item_link": "\/puntos-de-inters",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "trail-sign-sharp",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Visita virtual",
        "item_var": "visita_virtual",
        "item_link": "\/visita-virtual",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "glasses-sharp",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Visita f\u00edsica",
        "item_var": "visita_fsica__translate__",
        "item_link": "\/visita-fsica--translate--",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "walk-sharp",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "Bibliograf\u00eda",
        "item_var": "bibliografa__translate__",
        "item_link": "\/bibliografa--translate--",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "book-sharp",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "C\u00f3digos QR",
        "item_var": "codigos_qr",
        "item_link": "\/codigos-qr",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "light",
        "item_icon_left": "qr-code-outline",
        "item_color_icon_left": "medium",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    }
] ;

}
