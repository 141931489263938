/**
* @author lmcarrillo <lmcarrillo@sergas.net>
* @copyright Boqueixon 2023
* @version 20.60.60
* @license licenses.txt
*
* @date 2023-03-19 12:47:50
**/

import { Injectable } from "@angular/core";


@Injectable()
export class Globals {
	
	constructor(
		
	){



	}



	devInfo: any = {};


}
